<template>
 <div>
   <top></top>
   <router-view/>
   <bottom></bottom>
 </div>
</template>
<script>
import bottom from "@/layout/bottom/bottom.vue";
import top from "@/layout/top/top.vue";

export default {
  components: {
    top,
    bottom
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
  }
}
</script>
<style lang="scss" scoped>


</style>

<template>
  <div class="footer ">
    <div class="content footer-view">
        <div v-for="(item,index) in navList" :key="index">
            <div class="footer-view-title">{{ item.title }}</div>
            <img v-if="item.title" class="footer-view-border" src="../../assets/border-i.png" alt="">
            <ul>
                <li v-for="(child,i) in item.child" class="hand" @click="jumpPage(child.page)" :key="'child'+i">{{ child.title }}</li>
            </ul>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="content footer-bottom-flex">
            <div >©2023 上海屹脉信息科技有限公司   | <a style="color: #7B7C7E;" href="https://beian.miit.gov.cn" target="_blank">沪ICP备16033051号-1</a> | <a style="color: #7B7C7E;" href="https://www.beian.gov.cn" target="_blank">沪公网安备31010602000296号</a></div>
            <div style="margin-left:auto;color:#000;"><span style="color:#005CFE;">@ </span>service@vmainfo.com</div>
            <div style="margin-left:49px;color:#000;">
                <img src="../../assets/phone-i.png" style="width:14px;margin-right:8px;" alt="">021-36532622</div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
    data() {
        return {
            navList: [
                { title:'首页',child:[
                    { title:'公司介绍',page:'/index?t=1' },
                    { title:'行业洞察',page:'/index?t=2' },
                    { title:'解决方案',page:'/index?t=3' },
                    { title:'新闻动态',page:'/index?t=4' },
                ] },
                { title:'行业解决方案',child:[
                    { title:'数字政府',page:'/programme?t=3' },
                    // { title:'数字公交',page:'/programme?t=4' },
                    { title:'数字企业',page:'/programme?t=5' },
                    // { title:'公共设施数字化',page:'/programme?t=6' },
                ] },
                { title:'标杆案例',child:[
                    { title:'数字政府',page:'/case?t=1' },
                    // { title:'数字公交',page:'/case?t=2' },
                    { title:'数字企业',page:'/case?t=3' },
                    // { title:'公共设施数字化',page:'/case?t=4' },
                ] },
                // { title:'新闻动态',child:[
                //     { title:'行业洞察' },
                //     { title:'新闻动态' },
                // ] },
                { title:'关于我们',child:[
                    { title:'公司介绍',page:'/about?t=1' },
                    { title:'荣誉资质',page:'/about?t=2' },
                    { title:'屹脉团队',page:'/about?t=3' },
                ] },
                { title:'',child:[
                    { title:'合作伙伴',page:'/about?t=4' },
                    { title:'联系我们',page:'/about?t=5' },
                ] }
            ]
        }
    },
    methods: {
        jumpPage(page) {
            this.$router.push(page)
        }
    }
}
</script>

<style lang="scss" scoped>
   .footer{
   }
   .footer-view{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 42px 0;

    li{
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 9px;
    }
   }
   .footer-view-title{
        font-size: 16px;
        font-weight: bold;
        color: #000000;
   }
   .footer-view-border{
    width: 65px;
    margin:10px 0  24px;
   }
   .footer-bottom{
        background: #DADCE0;
        font-size: 14px;
        font-weight: 400;
        color: #7B7C7E;
        line-height: 40px;
   }
   .footer-bottom-flex{
    display: flex;
    align-items: center;
   }
</style>

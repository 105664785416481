<template>
  <div class="nav-top " id="nav">
    <div class="content nav-content">
        <img class="logo" src="../../assets/logo.png" alt="">
        <ul class="nav-list">
            <li v-for="(item,index) in list" :key="'nav'+index" @click="jumpPage(item.url)"
             :class="urlName===item.name?'active':''">{{ item.title }}</li>
        </ul>
        <div class="nav-mobile">
            <img src="../../assets/top-i.png" alt="" style="width: 22px;margin-right: 13px;">
            <span>021-3653 2622</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            list:[
                { title:'首页',name:'index',url:'/' },
                { title:'行业解决方案',name:'programme',url:'/programme' },
                { title:'标杆案例',name:'case',url:'/case' },
                // { title:'新闻动态',name:'news',url:'/news' },
                { title:'关于我们',name:'about',url:'/about' },
            ]
        }
    },
    computed: {
        urlName() {
            return this.$route.meta.name
        }
    },
    methods: {
        jumpPage(url) {
            this.$router.push(url);
        }
    }
}
</script>

<style lang="scss" scoped>
.logo{
    flex-shrink: 0;
    width: 104px;
    margin-right: 84px;
}
    .nav-top{
        background: #fff;
    }
    .nav-content{
        display: flex;
        align-items: center;
    }
    .nav-list{
        display: flex;
        align-items: center;
        li{
            font-size: 16px;
            font-weight: 400;
            margin-right: 58px;
            line-height: 80px;
            cursor: pointer;
            &.active{
                color: var(--color);
                &::after{
                    content:'';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 35px;
                    height: 3px;
                    background: var(--color);
                }
            }
        }
    }
    .nav-mobile{
        display: flex;
        align-items: center;
    }
    .nav-mobile{
        margin-left: auto;
    }
</style>

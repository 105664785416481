import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layout/index.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/',
    meta:{
      name:'index'
    },
    component: Layout,
    children: [
      {
        path: '/',
        component:() => import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
      },
      {
        path: '/case',
        name: 'case',
        meta:{
          name:'case'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/case/case.vue')
      },
      {
        path: '/detail',
        name: 'detail',
        meta:{
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/details/details.vue')
      },
      {
        path: '/news',
        name: 'news',
        meta:{
          name:'news'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/list/list.vue')
      },
      {
        path: '/industry',
        name: 'industry',
        meta:{
          name:'industry'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/list/list.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta:{
          name:'about'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/about/about.vue')
      },{
        path: '/programme',
        name: 'programme',
        meta:{
          name:'programme'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/programme/programme.vue')
      },
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    meta:{
      name:'contact'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/index.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router

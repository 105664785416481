<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import Top from '@/layout/top/top.vue'
import Bottom from '@/layout/bottom/bottom.vue'
  export default{
    components: {
      Top,
      Bottom
    },
    data() {
      return {

      }
    },
    watch:{
      // $route(val) {
      //   if(val) {
      //     console.log(val);
      //     document.getElementById('nav').scrollIntoView()
      //   }
      // }
    }
  }
</script>
<style lang="scss">
@import '@/assets/css/commen.scss';
#app{
  background: #F8F9FA;
  overflow-x: hidden;
}
</style>

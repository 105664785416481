import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Swiper from 'swiper'

import 'swiper/dist/css/swiper.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(ElementUI);
// // 跳转后返回顶部
//  router.afterEach((to,from,next) => {
//       window.scrollTo(0,0);
//  })
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
